import { graphql, PageProps } from 'gatsby'
import React from 'react'
import Grid from '../components/Grid'
import Layout from '../components/Layout'
import TravelCard from '../components/TravelCard'

export default function TravelPage({
  data,
}: PageProps<GatsbyTypes.TravelQuery>) {
  const travelPages = data.allMdx.nodes

  return (
    <Layout title="Travel">
      <Grid columns="2">
        {travelPages.map(({ frontmatter, slug }) => (
          <TravelCard
            key={slug}
            href={'/' + slug}
            title={frontmatter!.title}
            image={frontmatter!.cardImage?.childImageSharp!.gatsbyImageData!}
          />
        ))}
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query Travel {
    allMdx(filter: {slug: {glob: "travel/*"}}, sort: {fields: frontmatter___order}) {
      nodes {
        slug
        frontmatter {
          cardImage {
            childImageSharp {
              gatsbyImageData(
                width: 320
                height: 170
                layout: CONSTRAINED
                transformOptions: {cropFocus: CENTER}
                quality: 75
              )
            }
          }
          title
        }
      }
    }
  }
`
